// Importing images
import MyLogo from "./images/me.jfif";
import Profile from "./images/profile.jfif";
import Resume from "./Resume.pdf";

// Data object
const data = {
  name: "Devdas Kumar",
  texts: [
    'Student &  Developer & Ethical Hacker',
    'Pursuing BCA Cloud security from Amity University',
    '❤️ Programming and Cyber Security '
  ],
  logo: {
    src: MyLogo,
    alt: "My Logo"
  },
  profile: {
    src: Profile,
    alt: "Devdas Kumar"
  },
  resume: Resume,
  description: "Hi, I'm Devdas Kumar, a 21-year-old BCA student at Amity University with a passion for solving complex problems and crafting elegant, user-friendly solutions. As a perpetual learner, I thrive on challenges that drive personal and professional growth. Leveraging my expertise in ethical hacking and cybersecurity, I offer a diverse range of services, including securing systems, mentoring students, and delivering advanced training sessions. Whether working with individuals, organizations, or cyber cells, my goal is to empower others and create resilient defenses against evolving threats. Thank you for visiting! ❤️",
  social: {  
    email: "kumardevdas88@gmail.com",
    github: "https://github.com/devdas-gupta",
    linkedin: "https://www.linkedin.com/in/devdas-cybersecurity/",
    instagram: "https://www.instagram.com/devdas_kr",
    twitter: "https://twitter.com/devdasgupta10",
  },
  skills: {
    langs: [
      {
        name: "HTML5",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
      },
      {
        name: "CSS3",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
      },
      {
        name: "JavaScript",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
      },
      {
        name: "Python",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/python/python-original.svg",
      },
      {
        name: "C",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/c/c-original.svg",
      },
      {
        name: "C++",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/cplusplus/cplusplus-original.svg",
      },
      {
        "name": "Node.js",
        "icon": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg"
      },
      {
        name: "SQL",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mysql/mysql-original.svg",
      },
    ],
    technologies: [
      {
        name: "Android",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/android/android-original.svg",
      },
      {
        name: "Docker",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg",
      },
      {
        "name": "Tailwind CSS",
        "icon": "https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
      },
      {
        name: "React",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
      },
      {
        name: "Linux",
        icon: "/linux.svg",
      },
    ],

    tools: [
      {
        name: "VSCode",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/vscode/vscode-original.svg",
      },
      {
        name: "Github",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      },
      {
        name: "Android Studio",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/androidstudio/androidstudio-original.svg",
      },
      {
        name: "Docker",
        icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/docker/docker-original.svg",
      },
      {
        name: "Nmap",
        icon: "/nmap.svg",
      },
      {
        name: "Wireshark",
        icon: "https://upload.wikimedia.org/wikipedia/commons/d/df/Wireshark_icon.svg",
      },
      {
        name: "Kali Linux",
        icon: "/kali.png",
      },
      {
        name: "Hydra",
        icon: "/hydra.png",
      },
      {
        name: "Medusa",
        icon: "/medusa.png",
      },
      {
        name: "Nessus",
        icon: "/nessus.png",
      },
      {
        name: "Metasploit",
        icon: "/meta.png",
      },
      {
        name: "Git",
        icon: "/git.png",
      },
      {
        name: "Burp Suite",
        icon: "/burp.png",
      },
      {
        name: "Hashcat",
        icon: "https://hashcat.net/forum/images/hashcat-icon.png",
      },
    ],
  },
  experiences: [
    {
      date: "Jan 2023 - Present",
      title: "BCA with Specialization in Cloud and Security",
      subtitle: "Amity University,Noida, Uttar Pradesh,",
      description: "BCA with Cloud & Security: Prepares for IT roles in Cloud Solutions and Cybersecurity.",
      isworkcard: false,
    },
    {
      date: "Sep 2022 - Dec 2022",
      title: "Information Security Administrator | Internship ",
      subtitle: "Virtually Testing Foundation, California, USA",
      description: "I complete 10 different type of task in 10 weeks in Cyber Security, Network visibility , Business etiquette.",
      isworkcard: true,
    },
    {
      date: "Sep 2023",
      title: "CEH Practical Certified",
      subtitle: "Certified Ethical Hacker (CEH) Practical, from EC-Council",
      description: "CEH Practical Certified: Advanced training in ethical hacking with hands-on expertise, ready to tackle real-world cybersecurity challenges.",
      isworkcard: false,
    },
    {
      date: "Jan 2022 - jan 2023",
      title: "1 Year Diploma in Cyber Security",
      subtitle: "New Delhi, India",
      description: "Comprehensive 1-year diploma covering Networking, Python Programming, Hacking, Testing, Investigation, Application Security, Pentesting, IoT, and Security, including hands-on training, practical labs, and internship opportunities.",
      isworkcard: false,
    },
  ],
  projects: [
    {
      name: "Rotating Social Icons Card",
      description: "An interactive card with rotating social icons for your profile.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      technologies: [
        "HTML",
        "CSS"
      ],
      link: {
        github: "https://github.com/Devdas-gupta/Rotating-Social-Icons-Card",
        preview: "https://devdas-gupta.github.io/Rotating-Social-Icons-Card/Dev-card.html"
      }
    },
    {
      name: "Portfolio",
      description: "Welcome to my portfolio! This repository contains the source code for my portfolio website.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      technologies: [
        "HTML",
        "CSS",
        "JavaScript"
      ],
      link: {
        github: "https://github.com/devdas-gupta/Devdas-Kumar-Portfolio1",
        preview: "https://devdas-gupta.github.io/Devdas-Kumar-Portfolio1/profile.html"
      }
    },
    {
      "name": "SecurePass Generator",
      "description": "A web tool for generating secure passwords with customizable options.",
      "image": "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      "technologies": [
        "HTML",
        "CSS",
        "JavaScript"
      ],
      link: {
        github: "https://github.com/Devdas-gupta/SecurePass-Generator",
        preview: "https://devdas-gupta.github.io/SecurePass-Generator/PassGen.html"
      }
    },
    {
      name: "WebProbe",
      description: "WebProbe – Your toolkit for web reconnaissance! Uncover domains, IPs, and run enhanced nmap scans. Elevate your web testing!",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      technologies: ["Bash", "Amass", "Nmap", "FFUF"],
      link: { github: "https://github.com/Devdas-gupta/WebProbe" }
    },
    {
      name: "VibrantCalculator",
      description: "VibrantCalculator - A colorful button calculator built with HTML, CSS, and JavaScript.",
      image: "https://github.com/Devdas-gupta/VibrantCalculator/raw/main/clu.png",
      technologies: ["HTML", "CSS", "JavaScript"],
      link: {
        github: "https://github.com/Devdas-gupta/VibrantCalculator",
        preview: "https://devdas-gupta.github.io/VibrantCalculator/index.html"
      }
    },
    {
      name: "ChatInHash",
      description: "ChatInHash - A web application for encoding and decoding text using various hash algorithms like MD5, SHA-1, and SHA-256.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      technologies: ["Python", "Flask", "HTML", "CSS", "JavaScript"],
      link: {
        github: "https://github.com/Devdas-gupta/ChatInHash",
      }
    },
    {
      name: "CyberSentinel",
      description: "CyberSentinel - An interactive tool showcasing real-time geolocation, system insights, and camera snapshots with a sleek UI, designed for educational and ethical API integration demonstrations.",
      image: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original.svg",
      technologies: ["HTML", "CSS", "PHP", "JavaScript"],
      link: {
        github: "https://github.com/Devdas-gupta/CyberSentinel",
        preview: "https://devdas-gupta.github.io/CyberSentinel/"
      }
    },

  ]
};

export default data;