function GithubData({ repo }) {
    const githubUsername = repo.replace("https://github.com/", "");

    const generateBadgeUrl = (type) => `https://img.shields.io/github/${type}/${githubUsername}?style=social`;

    return (
        <div className="flex flex-wrap items-center justify-center mt-2 gap-3">
            <a target="_blank" rel="noreferrer" href={`${repo}/stargazers`}>
                <img className="w-24 h-auto" alt="GitHub stars" src={generateBadgeUrl("stars")} />
            </a>
            <a target="_blank" rel="noreferrer" href={`${repo}/network/members`}>
                <img className="w-24 h-auto" alt="GitHub forks" src={generateBadgeUrl("forks")} />
            </a>
        </div>
    );
}
    
export default GithubData;
