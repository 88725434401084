import React from "react";
import {
  FaLock,
  FaChalkboardTeacher,
  FaUsers,
  FaUserSecret,
  FaShieldAlt,
  FaServer,
} from "react-icons/fa";

function Services() {
  const services = [
    {
      icon: <FaUserSecret className="h-16 w-auto text-neon" />,
      title: "Collaborations with Cyber Cells",
      description:
        "Work with cyber cells to identify and analyze potential threats. Offer ethical hacking skills to help secure systems and monitor risks professionally.",
    },
    {
      icon: <FaUsers className="h-16 w-auto text-neon" />,
      title: "Consultation for Students",
      description:
        "Provide guidance and mentorship to students in cybersecurity and ethical hacking, helping them build a strong foundation for their careers.",
    },
    {
      icon: <FaChalkboardTeacher className="h-16 w-auto text-neon" />,
      title: "Online Courses & Tutorials",
      description:
        "Create easy-to-follow online courses on cybersecurity basics, ethical hacking, and advanced topics to help learners of all levels.",
    },
    {
      icon: <FaLock className="h-16 w-auto text-neon" />,
      title: "Penetration Testing",
      description:
        "Test systems for vulnerabilities and provide detailed solutions to strengthen security and protect against potential threats.",
    },
    {
      icon: <FaShieldAlt className="h-16 w-auto text-neon" />,
      title: "Security Awareness Training",
      description:
        "Conduct training programs to help individuals and teams understand and follow best practices in cybersecurity.",
    },
    {
      icon: <FaServer className="h-16 w-auto text-neon" />,
      title: "Incident Response & Recovery",
      description:
        "Assist in responding to security incidents and recovering systems effectively while preventing future issues.",
    },
  ];


  return (
    <section className="Services bg-[#1a1b26] py-20">
      <div id="services" className="container mx-auto px-4">
        <h2 className="text-center text-4xl md:text-5xl text-neon font-fira mb-8">
          Services
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div
              key={index}
              className="service-card bg-[#21222c] shadow-lg rounded-xl overflow-hidden hover:scale-105 transition-transform duration-300 ease-in-out w-full"
            >
              <div className="p-6">
                <div className="flex justify-center mb-4">{service.icon}</div>
                <h3 className="text-center text-xl text-neon font-semibold mb-2">
                  {service.title}
                </h3>
                <p className="text-gray-400 text-center">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
