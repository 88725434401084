import React from "react";
import { CgWorkAlt } from "react-icons/cg";
import { IoSchoolOutline } from "react-icons/io5";
import TitleAnim from "../containers/TitleAnim";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import data from "../../assets/data";

function Experiences() {
  return (
    <section className="Experiences mt-10 p-5">
      <TitleAnim title="Experiences" />
      <div className="mt-10">
        <VerticalTimeline>
          {data.experiences.map((exp) => (
            <VerticalTimelineElement
              key={exp.title}
              className={`vertical-timeline-element--${exp.isworkcard ? "work" : "education"}`}
              contentStyle={{ background: exp.isworkcard ? 'rgb(33, 150, 243)' : 'rgb(233, 30, 99)', color: '#fff' }}
              contentArrowStyle={{ borderRight: exp.isworkcard ? '7px solid rgb(33, 150, 243)' : '7px solid rgb(233, 30, 99)' }}
              date={exp.date}
              iconStyle={{ background: exp.isworkcard ? 'rgb(33, 150, 243)' : 'rgb(233, 30, 99)', color: '#fff' }}
              icon={exp.isworkcard ? <CgWorkAlt /> : <IoSchoolOutline />}
            >
              <h3 className="vertical-timeline-element-title">{exp.title}</h3>
              <h4 className="vertical-timeline-element-subtitle">{exp.subtitle}</h4>
              <p>{exp.description}</p>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default Experiences;
