import React from "react";
import { IoLogoTwitter, IoLogoGithub, IoLogoLinkedin } from "react-icons/io";
import data from "../../assets/data";

function Contact() {
  return (
    <footer className="Contact bg-[#1a1b26] py-10">
      <div id="contact" className="flex flex-col items-center justify-center">
        <h2 className="text-center text-3xl md:text-4xl text-neon font-fira">
          Contact Me
        </h2>

        <h3 className="text-center font-bold text-2xl md:text-3xl text-text mt-5">
          <a href={`mailto:${data.social.email}`} className="text-neon font-fira">
            {data.social.email}
          </a>
        </h3>

        <div className="flex flex-row gap-4 mt-6 z-10">
          <a
            href={data.social.twitter}
            target="_blank"
            rel="noreferrer"
            className="hover:bg-[#00acee] hover:text-white transition-colors rounded-lg h-12 w-auto "
          >
            <IoLogoTwitter className="h-12 w-auto border-2 rounded-lg p-1.5" />
          </a>
          <a
            href={data.social.github}
            target="_blank"
            rel="noreferrer"
            className="hover:bg-white hover:text-gray-800 transition-colors rounded-lg h-12 w-auto "
          >
            <IoLogoGithub className="h-12 w-auto border-2 rounded-lg p-1.5" />
          </a>
          <a
            href={data.social.linkedin}
            target="_blank"
            rel="noreferrer"
            className="hover:bg-[#0077b5] hover:text-white transition-colors rounded-lg h-12 w-auto"
          >
            <IoLogoLinkedin className="h-12 w-auto border-2 rounded-lg p-1.5" />
          </a>
        </div>

        <p className="text-xl text-gray-400 mt-6 mb-4">
          © {new Date().getFullYear()} ❤️ {data.name}. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Contact;
